
import { defineComponent, h } from "vue";
import { mapActions, mapGetters } from "vuex";
import { ActionTypes } from "@/store/actions/types";
import { ElDivider } from "element-plus";

export default defineComponent({
  name: "GameSession",
  data() {
    return {
      loading: false,
      search: undefined,
      customColors: [
        { color: "#5cb87a", percentage: 100 },
        { color: "#e6a23c", percentage: 75 },
        { color: "#f56c6c", percentage: 50 },
      ],
      customColorsTime: [
        { color: "#f56c6c", percentage: 100 },
        { color: "#5cb87a", percentage: 50 },
      ],
      spacer: h(ElDivider, { direction: "horizontal" }),
      currentPlayerTab: "first",
    };
  },
  methods: {
    ...mapActions({
      getSessionStats: ActionTypes.FETCH_STATS,
    }),
    async refreshStats() {
      this.loading = true;
      await this.getSessionStats({
        gameCode: this.$route.params.code,
        force: true,
      });
      this.loading = false;
    },
    formatSeconds(row: number, col: number, seconds: number) {
      if (seconds == undefined) {
        return "-";
      }
      let s = Math.round(seconds);
      return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    },
  },
  watch: {
    "$route.params.code": {
      immediate: true,
      async handler(code) {
        if (!code) return;
        this.loading = true;
        await this.getSessionStats({ gameCode: code, force: false });
        this.loading = false;
      },
    },
  },
  computed: {
    ...mapGetters(["getPlayersFromSession", "getQuestionsFromSession"]),
    getPlayerData(): Record<string, unknown>[] {
      return Object.keys(
        this.getPlayersFromSession(this.$route.params.code)
      ).map((key: string) => {
        const entry = this.getPlayersFromSession(this.$route.params.code)[key];
        entry.alias = key;
        entry.totalAnswers = entry.answers.reduce(
          (accumulator: number, currentValue: number) =>
            accumulator + currentValue
        );
        return entry;
      });
    },
    getAverageTimeObject(): Record<string, number> {
      let numberPlayers = 0;
      let sumTime = 0;
      let largest = 0;
      Object.keys(this.getPlayersFromSession(this.$route.params.code)).forEach(
        (key: string) => {
          numberPlayers++;
          if (
            this.getPlayersFromSession(this.$route.params.code)[key].time >
            largest
          )
            largest = this.getPlayersFromSession(this.$route.params.code)[key]
              .time;
          sumTime += this.getPlayersFromSession(this.$route.params.code)[key]
            .time;
        }
      );
      let averageTime = sumTime / numberPlayers;
      // let s = Math.round(averageTime)

      return !isNaN(averageTime)
        ? {
            percentage: ((largest - averageTime) / largest) * 100,
            averageTime: Math.round((averageTime + Number.EPSILON) * 100) / 100,
          }
        : { percentage: 0, averageTime: 0 };
    },
    getQuestionData(): unknown {
      let averageAnswers: number[] = [];
      let numberPlayers = 0;
      Object.keys(this.getPlayersFromSession(this.$route.params.code)).forEach(
        (key: string) => {
          numberPlayers++;
          const entry = this.getPlayersFromSession(this.$route.params.code)[
            key
          ];
          entry.answers.forEach(
            (answer: number, index: number) =>
              (averageAnswers[index] = (averageAnswers[index] ?? 0) + answer)
          );
        }
      );

      const swedish = this.$i18n.locale === "sv";

      return this.getQuestionsFromSession(this.$route.params.code, swedish).map(
        (question: Record<string, string>, index: number) => {
          let average = (averageAnswers[index] ?? 0) / numberPlayers;
          if (isNaN(average)) average = 0;
          else average = Math.round((average + Number.EPSILON) * 100) / 100;
          return {
            title: question.title,
            options: question.options,
            correct: question.correct,
            average,
          };
        }
      );
    },
  },
});
